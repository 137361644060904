<template>
  <div class="d-flex payu-form">
    <v-card class="form-card mr-4 pa-5">
      <div class="tway-headline-2">Datos tarjeta</div>
      <div class="sub-headline mt-2">Escribe y selecciona los datos de tu tarjeta para realizar el pago.</div>
      <div class="mt-5">Selecciona una tarjeta de crédito</div>
      <card-selector class="mt-3" @click="(value) => (payuData.issuer = value)" />
      <div v-if="payuData.issuer === '' && payClicked" class="mt-2 roboto" style="color: red">
        Selecciona una tarjeta
      </div>

      <v-form v-model="valid">
        <div class="mt-5">Nombre y apellido del titular de la tarjeta</div>
        <v-text-field
          v-model="payuData.payerName"
          class="mt-2 roboto"
          placeholder="Escribe el nombre y apellido aquí"
          maxlength="150"
          :rules="[required('nombre')]"
          outlined
        />
        <div class="mt-10">Número de la tarjeta</div>
        <v-text-field
          v-model="payuData.cardNumber"
          v-creditCard
          class="mt-2 roboto"
          placeholder="XXXX XXXX XXXX XXXX"
          @input="onChangeCardNumber"
          maxlength="19"
          :rules="[required('número de tarjeta')]"
          outlined
        />
        <div class="d-flex mt-10">
          <div class="d-block" style="width: 100%">
            <div>Fecha de expiración</div>
            <v-text-field
              v-model="payuData.expirationDate"
              class="mt-2 roboto"
              placeholder="MM/AA"
              outlined
              maxlength="5"
              v-onlyNumbers
              :rules="[
                required('fecha', 'Escribe una fecha válida.'),
                minLength('fecha', 1, 'Escribe una fecha válida.'),
              ]"
              @input="onChangeExpirationDate"
            />
          </div>
          <div class="ml-5" style="width: 100%">
            <div>CVV</div>
            <v-text-field
              v-model="payuData.cardSecurityCode"
              class="mt-2 roboto"
              placeholder="..."
              v-onlyNumbers
              maxlength="3"
              :type="showCode ? 'text' : 'password'"
              @click:append="showCode = !showCode"
              :append-icon="showCode ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :rules="[required('código'), minLength('código', 3)]"
              outlined
            />
          </div>
        </div>
        <div class="tway-headline-2 mt-15">Datos de Facturación</div>
        <div class="d-flex mt-2">
          <div class="d-block" style="width: 100%">
            <div>Nombre y apellido</div>
            <v-text-field
              class="mt-2 roboto"
              placeholder="Escribe tu nombre aquí"
              outlined
              maxlength="150"
              :rules="[required('nombre')]"
            />
          </div>
          <div class="ml-5" style="width: 100%">
            <div>Correo</div>
            <v-text-field
              v-model="payuData.payerEmail"
              class="mt-2 roboto"
              placeholder="Escribe tu correo aquí"
              maxlength="255"
              :rules="[required('correo'), validateEmail]"
              outlined
            />
          </div>
        </div>
        <div class="d-flex mt-10">
          <div class="d-block" style="width: 100%">
            <div>Número de contacto</div>
            <v-text-field
              v-model="payuData.payerContactPhone"
              class="mt-2 roboto"
              placeholder="Escribe tu número aquí"
              maxlength="20"
              :rules="[required('número')]"
              outlined
            />
          </div>
          <div class="ml-5" style="width: 100%">
            <div>DNI</div>
            <v-text-field
              v-model="payuData.payerDni"
              class="mt-2 roboto"
              placeholder="Escribe tu número de identificación"
              maxlength="20"
              :rules="[required('DNI')]"
              outlined
            />
          </div>
        </div>
        <div class="mt-10">Dirección de facturación</div>
        <v-text-field
          v-model="payuData.payerAddressStreet"
          class="mt-2 roboto"
          placeholder="Escribe tu dirección"
          maxlength="50"
          :rules="[required('dirección', 'Escribe una dirección válida.')]"
          outlined
        />
        <v-text-field
          v-model="streetAditionalInfo"
          style="width: 50%"
          class="mt-8 roboto"
          placeholder="Información adicional dirección"
          maxlength="35"
          outlined
        />
        <div class="mt-n5 help-text">Ej: Apartamento 203, piso 2.</div>
        <div class="d-flex mt-8">
          <div class="d-block" style="width: 100%">
            <div>País</div>
            <v-text-field
              v-model="payuData.payerCountry"
              class="mt-2 roboto"
              placeholder="Escribe tu número aquí"
              outlined
              disabled
            />
          </div>
          <div class="ml-5" style="width: 100%">
            <div>Departamento</div>
            <v-text-field
              v-model="apartmentCode"
              class="mt-2 roboto"
              placeholder="Escribe tu número de identificación"
              maxlength="15"
              outlined
            />
          </div>
        </div>
        <div class="d-flex mt-10">
          <div class="d-block" style="width: 100%">
            <div>Ciudad</div>
            <v-text-field
              v-model="payuData.payerCity"
              class="mt-2 roboto"
              placeholder="Escribe una ciudad"
              maxlength="50"
              :rules="[required('ciudad', 'Selecciona una ciudad.')]"
              outlined
            />
          </div>
          <div class="ml-5" style="width: 100%">
            <div>Código Postal</div>
            <v-text-field
              v-model="payuData.payerPostalCode"
              class="mt-2 roboto"
              placeholder="Escribe tu código postal"
              maxlength="8"
              :rules="[required('código postal')]"
              outlined
              v-onlyNumbers
            />
          </div>
        </div>
        <div class="mt-10">Teléfono celular</div>
        <v-text-field
          v-model="payuData.payerPhone"
          style="width: 50%"
          class="mt-2 mb-8 roboto"
          placeholder="Ej: +57912345678"
          maxlength="20"
          :rules="[required('número celular', 'Escribe un número celular.')]"
          outlined
        />
      </v-form>
    </v-card>
    <div class="summary-card-container">
      <v-card class="summary-card pa-5">
        <div class="tway-headline-2">Resumen de pago</div>
        <div class="item" style="margin-top: 10px">
          <strong>Fecha: </strong>
          <span>{{ new Date() | formatDateTime }}</span>
        </div>
        <div class="item">
          <strong>Descripción de pago: </strong>
          <span> {{ payuData.description | capitalize }} </span>
        </div>
        <div class="item">
          <strong>Valor original: </strong>
          <span>{{ payuData.amount | currency }}</span>
        </div>
        <v-divider class="mt-4" />
        <div class="item total mt-4">
          <strong>Total a pagar: </strong>
          <span>USD {{ payuData.amount | currency }}</span>
        </div>
        <v-btn
          rounded
          color="tway-apple-green"
          block
          class="white--text roboto mt-9 mb-4"
          :loading="loadingPay"
          @click="pay"
          :disabled="!valid"
        >
          <strong> PAGAR</strong>
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
import CardSelector from "@/components/subscription/CardSelector";
import subscriptionService from "@/services/subscriptionService";
import ValidatorsService from "@/services/validatorsService";
import { v4 as uuidv4 } from "uuid";

const DEFAUL_VALUES = {
  currencyCode: "USD",
  payerCountry: "COLOMBIA",
  paymentMethod: "PAYU_SINGLE_PAY",
  paymentMethodSubscription: "PAYU_SUBSCRIPTION",
  installments: "1",
  countryCode: "CO",
  payerDocumentType: "CC",
};

export default {
  components: {
    CardSelector,
  },
  data() {
    return {
      valid: null,
      planName: "",
      planId: "",
      payuData: {
        reference: "",
        amount: 0,
        description: "",
        currencyCode: DEFAUL_VALUES.currencyCode,
        cardNumber: "",
        cardSecurityCode: "",
        expirationDate: "",
        issuer: "",
        installments: DEFAUL_VALUES.installments,
        payerId: "",
        payerName: "",
        payerEmail: "",
        payerContactPhone: "",
        payerDni: "",
        payerAddressStreet: "",
        payerAddressStreet2: "",
        payerCity: "",
        payerState: "",
        payerCountry: DEFAUL_VALUES.payerCountry,
        payerPostalCode: "",
        payerPhone: "",
        paymentMethod: DEFAUL_VALUES.paymentMethod,
      },
      streetAditionalInfo: "",
      apartmentCode: "",
      paymentType: "",

      showCode: false,
      payClicked: false,
      loadingPay: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    //TODO: aqui se debe agregar una restricción y redireccion cuando no se reciben parametros
    next();
  },
  mounted() {
    this.$el.scrollIntoView(null);
    let params = this.$route.params;

    this.paymentType = params.paymentType;
    this.subscriptionId = params.subscriptionId;
    this.payuData.amount = params.price;
    this.planName = params.planName;
    this.planId = params.planId;
    this.payuPlanId =
      params.paymentMethodInfo && params.paymentMethodInfo.meta && params.paymentMethodInfo.meta.payuPlanCode
        ? params.paymentMethodInfo.meta.payuPlanCode
        : null;
    this.payuData.description = `Plan ${params.planName} Tway`;
    this.payuData.payerId = params.clientId;
  },
  methods: {
    onChangeCardNumber(evt) {
      let value = evt.replace(/ /g, "").replace(/-/g, "");
      let formatedText = "";
      if (value.length <= 4) {
        formatedText = value;
      } else {
        formatedText = value.slice(0, 4);
        for (let i = 4; i < value.length; i = i + 4) {
          formatedText = formatedText + " " + value.slice(i, i + 4);
        }
      }
      this.payuData.cardNumber = formatedText;
    },
    onChangeExpirationDate(evt) {
      if (evt.length === 2) {
        this.payuData.expirationDate = evt + "/";
      }
    },
    pay() {
      this.payClicked = true;
      if (this.payuData.issuer !== "") {
        this.loadingPay = true;
        let data = {
          reference: `TWAY-plan-${this.planName}-${this.subscriptionId}-${uuidv4()}`,
          amount: this.payuData.amount,
          description: this.payuData.description,
          currencyCode: DEFAUL_VALUES.currencyCode,
          cardNumber: this.payuData.cardNumber.replace(/ /g, ""),
          cardSecurityCode: this.payuData.cardSecurityCode,
          expirationDate: this.formatDate(this.payuData.expirationDate),
          issuer: this.payuData.issuer,
          installments: DEFAUL_VALUES.installments,
          payerId: this.payuData.payerId,
          payerName: this.payuData.payerName,
          payerEmail: this.payuData.payerEmail,
          payerContactPhone: this.payuData.payerContactPhone,
          payerDni: this.payuData.payerDni,
          payerDocumentType: this.payuData.issuer === "CODENSA" ? DEFAUL_VALUES.payerDocumentType : null,
          payerAddressStreet:
            this.payuData.payerAddressStreet + "" + this.apartmentCode + " " + this.streetAditionalInfo,
          payerAddressStreet2: "",
          payerCity: this.payuData.payerCity,
          payerState: "",
          payerCountry: DEFAUL_VALUES.countryCode,
          payerPostalCode: this.payuData.payerPostalCode,
          payerPhone: this.payuData.payerPhone,
          paymentMethod:
            this.paymentType === "ONCE" ? DEFAUL_VALUES.paymentMethod : DEFAUL_VALUES.paymentMethodSubscription,
          planId: this.payuPlanId,
        };
        console.log(data, this.subscriptionId, this.paymentType);
        subscriptionService
          .payPayu(data, this.subscriptionId, this.paymentType)
          .then((resp) => {
            let prevFormData = { ...data, planId: this.planId };
            this.$router.push({
              name: "success-payment-payu",
              params: { paymentData: resp, prevFormData },
            });
          })
          .catch((err) => {
            this.$log.error(err);
            this.$router.push({ name: "error-payment" });
          })
          .finally(() => (this.loadingPay = false));
      } else {
        this.$el.scrollIntoView(null);
      }
    },
    formatDate(dateStr) {
      let parts = dateStr.split("/");
      let month = parts[0];
      let year = dateStr.length > 0 ? "20" + parts[1] : "";
      return `${year}/${month}`;
    },
    required: function (propertyType, customMsg) {
      return (v) => (v && v.length > 0) || (customMsg ? customMsg : `Escribe un ${propertyType} válido.`);
    },
    minLength: function (propertyType, minLength, customMsg) {
      return (v) => (v && v.length >= minLength) || (customMsg ? customMsg : `Escribe un ${propertyType} válido.`);
    },
    validateEmail: ValidatorsService.validateEmail(),
  },
};
</script>

<style>
.payu-form .form-card {
  flex-basis: 67%;
}
.payu-form .summary-card-container {
  flex-basis: 33%;
  height: fit-content;
}
.payu-form .summary-card {
  position: fixed;
  width: calc(30% - 48px);
}
.payu-form .sub-headline {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.payu-form .v-application .primary--text {
  caret-color: #7319d5;
  color: #7319d5 !important;
}
.payu-form .help-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #999999;
}
.payu-form .summary-card .item {
  font-family: "Roboto Condensed";
  font-size: 16px;
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
}
.payu-form .summary-card .item.total {
  font-size: 18px;
  color: #7319d5;
}

.payu-form .v-messages__message {
  color: red !important;
}
</style>
