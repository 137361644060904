<template>
  <div class="d-flex">
    <v-img
      v-for="(c, index) in cards"
      :key="index"
      :src="c.img"
      :class="['mr-5', 'card-item', selected && selected.key === c.key ? 'selected-card' : null]"
      max-width="70"
      max-height="48"
      style="cursor: pointer"
      @click="onClick(c)"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          img: require("@/assets/img/payment-cards/amex.svg"),
          key: "AMEX",
        },
        {
          img: require("@/assets/img/payment-cards/master-card.svg"),
          key: "MASTERCARD",
        },
        {
          img: require("@/assets/img/payment-cards/visa.svg"),
          key: "VISA",
        },
        {
          img: require("@/assets/img/payment-cards/codensa.svg"),
          key: "CODENSA",
        },
        {
          img: require("@/assets/img/payment-cards/other.svg"),
          key: "DINERS",
        },
      ],
      selected: null,
    };
  },
  methods: {
    onClick(option) {
      this.selected = option;
      this.$emit("click", option.key);
    },
  },
};
</script>

<style lang="css" scoped>
.selected-card {
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  border-color: #7319d5;
  background-color: black;
}
.card-item:hover {
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  border-color: #7319d5;
  background-color: #7319d5;
}
</style>
